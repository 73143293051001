import React, { useState } from "react";
import { MenuItems } from "../data/MenuItems";
import "./styles/ResourcesDropdown.css";
import { Link } from "react-router-dom";

function ResourcesDropdown() {
	const [click, setClick] = useState(false);

	const handleClick = () => setClick(!click);

	return (
		<>
			<ul onClick={handleClick} className={click ? "services-submenu clicked" : "services-submenu"}>
				{MenuItems.map((item, index) => {
					return (
						<li key={index}>
							<Link className="submenu-item" to={"resources" + item.path} onClick={() => setClick(false)}>
								{item.title}
							</Link>
						</li>
					);
				})}
			</ul>
		</>
	);
}

export default ResourcesDropdown;
