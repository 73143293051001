import React from "react";
import "./styles/CalculationEquation.css";

const RecCaloriesEquation = () => {
	return (
		<div className="equationBox">
			<div className="equation">Recommended Daily Calories = Activity Level Coefficient * RMR</div>
		</div>
	);
};

export default RecCaloriesEquation;
