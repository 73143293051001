export const heightOptions = [
	// For value, put the underlying CM value
	{ value: 137.16, label: "4' 6\"" },
	{ value: 139.7, label: "4' 7\"" },
	{ value: 142.24, label: "4' 8\"" },
	{ value: 144.78, label: "4' 9\"" },
	{ value: 147.32, label: "4' 10\"" },
	{ value: 149.86, label: "4' 11\"" },
	{ value: 152.4, label: "5' 0\"" },
	{ value: 154.94, label: "5' 1\"" },
	{ value: 157.48, label: "5' 2\"" },
	{ value: 160.02, label: "5' 3\"" },
	{ value: 162.56, label: "5' 4\"" },
	{ value: 165.1, label: "5' 5\"" },
	{ value: 167.64, label: "5' 6\"" },
	{ value: 170.18, label: "5' 7\"" },
	{ value: 172.72, label: "5' 8\"" },
	{ value: 175.26, label: "5' 9\"" },
	{ value: 177.8, label: "5' 10\"" },
	{ value: 180.34, label: "5' 11\"" },
	{ value: 182.88, label: "6' 0\"" },
	{ value: 185.42, label: "6' 1\"" },
	{ value: 187.96, label: "6' 2\"" },
	{ value: 190.5, label: "6' 3\"" },
	{ value: 193.04, label: "6' 4\"" },
	{ value: 195.58, label: "6' 5\"" },
	{ value: 198.12, label: "6' 6\"" },
	{ value: 200.66, label: "6' 7\"" },
	{ value: 203.2, label: "6' 8\"" },
	{ value: 205.74, label: "6' 9\"" },
	{ value: 208.28, label: "6' 10\"" },
	{ value: 210.82, label: "6' 11\"" },
	{ value: 213.36, label: "7' 0\"" },
];
