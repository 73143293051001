export default class CalorieCalculator {
	constructor(age, sex, heightCm, weightKg, activityLevel, goal) {
		this.age = Number(age);
		this.sex = sex;
		this.heightCm = Number(heightCm);
		this.weightKg = Number(weightKg);
		this.activityLevel = activityLevel;
		this.goal = goal;
	}

	createActivityMap() {
		const activityLevelMap = new Map();
		activityLevelMap.set("sedentary", 1.2);
		activityLevelMap.set("light", 1.375);
		activityLevelMap.set("moderate", 1.55);
		activityLevelMap.set("heavy", 1.725);
		return activityLevelMap;
	}

	getMifflinStJeorTdee() {
		let calories = Math.trunc(10 * this.weightKg + 6.25 * this.heightCm - 5 * this.age);
		const activityMap = this.createActivityMap();
		if (this.sex === "male") {
			calories += 5;
		} else {
			calories -= 161;
		}
		const activityCoefficient = activityMap.get(this.activityLevel);
		return Math.trunc(calories * activityCoefficient);
	}

	getResult() {
		const tdee = this.getMifflinStJeorTdee();
		let dailyCalories = tdee;
		if (this.goal === "gain") {
			dailyCalories += 500;
		} else if (this.goal === "lose") {
			dailyCalories -= 500;
		}
		var result = {
			tdee: tdee,
			dailyCalories: dailyCalories,
		};
		return result;
	}
}
