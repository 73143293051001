import React from "react";
import "./styles/PrivacyView.css";

const PrivacyView = () => {
	return (
		<div className="privacyContainer">
			<h1 className="title">Privacy</h1>

			<p className="privacyParagraph">
				Your information is protected, and none of your personal data is stored. Site analytics may be stored in
				the future as more resource pages are added. This will help me get a better idea of what type of
				additional content to include. Once that happens, additional privacy policy information will be
				displayed here.
			</p>
			{/* <p className="subheading">If you have any additional questions, feel free to email me @ "...".</p> */}
		</div>
	);
};

export default PrivacyView;
