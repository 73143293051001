import React from "react";
import "./styles/CalculationEquation.css";

const MifflinStJeorEq = () => {
	return (
		<>
			<div className="equationBox">
				<div className="equation">
					{/* <div className="formula"> */}
					(10 * <div className="variable">&nbsp;weightInKg</div>) + (6.25 *
					<div className="variable">&nbsp;heightInCm</div>) - (5 * <div className="variable">&nbsp;age</div>)
					+&nbsp;
					<div className="variable">sexConstant</div>
				</div>
				<div className="equationHelper">sexConstant = (5) for Males and (-161) for Females</div>
			</div>
		</>
	);
};

export default MifflinStJeorEq;
