import React from "react";
import "./styles/RecCalories.css";

const RecCalories = (goalId, tdee) => {
	if (goalId === "lose") {
		return (
			<div className="container">
				<p>
					Your estimated maintenance calories(the calories needed to maintain your weight) are calculated to
					be <a className="underline">{tdee}</a> per day. When losing weight, it is recommend to eat at a
					deficit of 500 calories per day. 3500 calories ~ aproximates to 1lb of bodyweight, so a deficit of
					500 for 7 days would result in about a loss of a pound.
				</p>
				<p>
					A more aggressive approach is to be at a deficit of 1000 calories per day, but this is more rigorous
					and can cause burn out. It is advised if you are not used to counting calories and cutting weight to
					first start at a deficit of 500 calories.
				</p>
			</div>
		);
	} else if (goalId === "gain") {
		return (
			<div className="container">
				<p>
					Your estimated maintenance calories(the calories needed to maintain your weight) are calculated to
					be <a className="underline">{tdee}</a> per day. When gaining weight it is important to gain weight
					at a slow and steady rate to minimize fat gain. It is recommended to eat at a 500 calorie surplus
					while eating a high amount of protein in conjunction with weight lifting to maximize your rate of
					muscle gain. If you find yourself gaining weight at a higher rate than 1lb a week, try slighlty
					adjusting your calories a bit lower to try to be at the ~1lb a week rate.
				</p>
			</div>
		);
	} else {
		return (
			<div className="container">
				<p>
					Your estimated maintenance calories(the calories needed to maintain your weight) are calculated to
					be <a className="underline">{tdee}</a> per day. We want to stick to our maintenance calories as
					close as possible when we want to maintaing our weight. However, dont feel constricted and let it
					stop you from enjoying life and eating out/treating yourself every once in a while. If you ate over
					your calories on a certain day or weekend, dont feel the need to eat at a harsh defecit to balance
					it out. If you go back to your maintenance calories your weight will balance out.
				</p>
			</div>
		);
	}
};

export default RecCalories;
