export const faqValues = [
	{
		id: 1,
		question: "What is the importance of counting calories?",
		answer:
			"Most diets tend to restrict food to a certain subset. This works because more often than not you are not eating high calorie/processed foods and are more conscious of the type/amount of food being consumed. " +
			"However, all diets follow the same underlying principle, Calories in, calories out (CICO). What this essentially means is that you need to consume less calories than you burn to lose weight (the opposite for gaining weight). " +
			"1 pound of bodyweight roughly comes out to be 3500 calories. This means if you are in a daily caloric defecit of 500 calories for 1 week, you should lose ~ 1 pound. " +
			"500(daily caloric defecit) * 7(days) = 3500(total caloric defecit). By counting calories and measruing our food, we can get the most accurate account of the amount of calories consumed. " +
			"Helping us to get a better sense if we are correctly adhering to our diet.",
	},
	{
		id: 2,
		question: "How do I count calories?",
		answer:
			"Counting calories is essentially keeping track of what you have eaten and the amount of calories you have consumed. The best way to keep track of your caloires is by using a food tracking app like MyFitnessPal or LoseIt!. " +
			"These apps let you scan barcodes of different food items to record the corresponding nutrition information. In conjunction with the apps, you will need to measure your food to get the most accurate totals for the amount of food you eat. " +
			"I recommend using a food scale to weight out your food to be as precise as possible. These food tracking apps also have community input/created food recipies, so you can also find food from your favorite restaraunts so that you can still keep track of your calories/diet while eating out.",
	},
	{
		id: 3,
		question: "Should I exercise more?",
		answer:
			"Exercise is great, and it is recommened to do a combination of weight tranining and cardio to improve your overall fitness. However, one caveat would be that " +
			"if you are new to counting calories (and aim to lose weight), if you drastically increase your excercies right off the bat you would be in a greater risk to fall off the diet since there are many moving parts and it can become draining." +
			" I would recommend to slowly increase your excercise/activty level while you get accustomed to counting calories, and become used to the overall less amount of food you are consuming compared to your the calories you consumed pre-diet. For people looking to gain weight it is highly suggested to lift weights and eat a high protein diet." +
			" By doing both of these things you can better add muscle to your frame while you are bulking up. If you are eating at a caloric surplass, but are not eating a good amount of protein and not lifting weights, you will primarily be adding fat to your body and that isnt the most beneficial.",
	},
	{
		id: 4,
		question: "What foods should I eat (and what should I avoid)?",
		answer:
			"Foods that are high in protein are a staple. Protein rich foods have high satiety, keeping you full longer which helps avoid overeating and snacking. " +
			"Vegetables and frutis are also beneficial, since for the most part they are lower in calories and help improve your overall health. Anohter benefit of " +
			"eating a high protein diet is that it helps you better maintain your current muscle mass while you lose weight." +
			"For more info look at our Diet resources page. (LINK)",
	},
	{
		id: 5,
		question: "Why am I not losing/gaining weight?",
		answer:
			"This calculator computes your recommeneded calorie goal, so there is a chance that it might be not be the exact calories you are burning. Altought this calculator is fairly accurate, there may be other factors causing your weight to not change. " +
			"Most people seem to overestimate the level of exercise they actually do, so I recommended to start on the more conservitive side when selecting your activity level and then fine tune your daily calories after a few weeks eating at your initial estimate. " +
			"Another common problem is that cooking oils and sauces are not tracked correctly, causing your daily calorie totals to be off. Butter, oil, and other sauces contain a high amount of calories, so it is very possible that can be the reason why your weight is not changing if you are tracking your calories with everything else." +
			"There can also be other factors causing your recommended calories to be slightly off, so it is recommended to drop/increase your daily calories by 100 and track your weight for the next 1-2 week(s). If you are not seeeing a change, you can repeat the process. " +
			"Also, if you have made a decent change in your weight(~10lbs/5kg) since your initial " +
			"calorie calculation, it is recommened to recompute your daily calories since being at a different weight causes you to brun a different amount of calories.",
	},
];
