import React from "react";

export const MenuItems = [
	{
		title: "Diet",
		path: "/diet",
		className: "submenu-item",
	},
	{
		title: "Calorie Tracking",
		path: "/calorie-tracking",
		className: "submenu-item",
	},
];
