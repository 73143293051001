import React from "react";
import MifflinStJeorEq from "./MifflineStJeorEq";
import "./styles/CalculationEquation.css";

const CalculationEquation = () => {
	return (
		<>
			<h1 className="subtitle">How are the recommend calories calculated?</h1>
			<div className="container">
				<p>
					This is calculated using the Mifflin-St Jeor equation for RMR (Resting Metabolic Rate). Your Resting
					Metabolic Rate is the calories required by the body to be in a resting condition. Once we have your
					RMR, we can multiply that number by a coefficient that corresponds to your activity level. More
					information on the Mifflin-St Jeor equation can be found here on the corresponding{" "}
					<a className="pubmedLink" href="https://pubmed.ncbi.nlm.nih.gov/2305711/">
						PubMed research paper
					</a>
					.
				</p>
			</div>
			<div className="equationTitle">Mifflin-St Jeor Equation</div>
			<MifflinStJeorEq />
			{/* https://pubmed.ncbi.nlm.nih.gov/2305711/ */}
			{/* (total daily energy expenditure) */}
		</>
	);
};

export default CalculationEquation;
