import React from "react";
import "./styles/UnderConstruction.css";

const UnderConstruction = () => {
	return (
		<div className="constructionContainer">
			<div className="constructionHeader">
				<i class="fa-solid fa-screwdriver-wrench"></i>
				<h2>Under Construction</h2>
				<i class="fa-solid fa-screwdriver-wrench"></i>
			</div>
			<div className="subheading">Thank you for your patience.</div>
		</div>
	);
};

export default UnderConstruction;
