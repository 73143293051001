import React from "react";
import UnderConstruction from "../components/UnderConstruction";

const DietView = () => {
	return (
		<div>
			<h1 className="title">Diet</h1>
			{<UnderConstruction />}
		</div>
	);
};

export default DietView;
