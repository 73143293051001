import React from "react";
import { faqValues } from "../data/FAQValues";
import "./styles/FaqView.css";

const FaqView = () => {
	return (
		<>
			<h1 className="title">FAQ</h1>
			<div className="faqContainer">
				{faqValues.map((item) => {
					return (
						<>
							<div className={"faqPair " + (item.id % 2 ? "" : "greyBackground")}>
								<h1 className="question">{item.question}</h1>
								<p className="answer">{item.answer}</p>
							</div>
						</>
					);
				})}
			</div>
		</>
	);
};

export default FaqView;
