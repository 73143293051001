import React from "react";
import "./styles/ActivityTable.css";
import { activityOptions } from "../data/ActivityOptions";

const ActivityTable = () => {
	return (
		<>
			<table id="activityTable">
				<tr>
					<th>Activity Level</th>
					<th>Coefficient</th>
				</tr>

				{activityOptions.map((item, index) => {
					return (
						<tr key={index}>
							<td>{item.label}</td>
							<td>{item.coefficient}</td>
						</tr>
					);
				})}
			</table>
		</>
	);
};

export default ActivityTable;
