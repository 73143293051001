import React from "react";
import "./styles/HomeView.css";
import InputForm from "../components/InputForm";
import InputFormMobile from "../components/InputFormMobile";

const HomeView = () => {
	return (
		<>
			<h1 className="title">
				Calculate Recommended
				<br />
				Daily Calories
			</h1>

			<p className="subheading">Find a starting point for your weight management journey!</p>
			{<InputForm />}
			{<InputFormMobile />}
		</>
	);
};

export default HomeView;
