import { useState } from "react";
import { Link } from "react-router-dom";
import ResourcesDropdown from "./ResourcesDropdown";
import { MenuItems } from "../data/MenuItems";
import "./styles/SlidingNav.css";

function SlidingNav() {
	const [isSidebarActive, setSidebarActive] = useState(false);
	const [dropdown, setDropdown] = useState(false);

	const handleClick = () => setSidebarActive(!isSidebarActive);
	const closeSidebar = () => setSidebarActive(false);

	const onMouseEnter = () => {
		if (window.innerWidth < 960) {
			setDropdown(false);
		} else {
			setDropdown(true);
		}
	};

	const onMouseLeave = () => {
		setDropdown(false);
	};

	return (
		<>
			<nav>
				{/* Main NavBar UL */}
				<ul>
					<li className="logo">
						<Link to="/" className="logo" onClick={closeSidebar}>
							Daily Calorie Calc&nbsp;
							<i className="fa-solid fa-weight-scale"></i>
						</Link>
					</li>

					<li className="hideOnMobile navMenuItem" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
						<Link onClick={closeSidebar} display="in-block">
							Resources&nbsp;
							<i className="fas fa-caret-down" />
						</Link>
						{dropdown && <ResourcesDropdown />}
					</li>

					<li className="hideOnMobile navMenuItem">
						<Link to="/faq">FAQ</Link>
					</li>

					<li className="hideOnMobile navMenuItem">
						<Link to="/about">About</Link>
					</li>
				</ul>

				{/* SideBar UL */}
				<ul className={isSidebarActive ? "sidebar" : "sidebarHidden"}>
					<li className="firstMenuItem sidebarItem" onClick={handleClick}>
						<Link to="/">Home</Link>
					</li>

					<li className="nonHoverable">
						<Link>
							Resources&nbsp; <i className="fas fa-caret-down" />
						</Link>
					</li>

					{MenuItems.map((item, index) => {
						return (
							<li key={index}>
								<Link className="sidebarSubItem" to={"resources" + item.path} onClick={handleClick}>
									{item.title}
								</Link>
							</li>
						);
					})}

					<li className="sidebarItem" onClick={handleClick}>
						<Link to="/faq">FAQ</Link>
					</li>

					<li className="sidebarItem" onClick={handleClick}>
						<Link to="/about">About</Link>
					</li>
				</ul>

				{/* Sidebar menu Icons */}
				<div onClick={handleClick} className="hideOnStandard menu-icon">
					<div>
						<i className={isSidebarActive ? "fas fa-times" : "fas fa-bars"} />
					</div>
				</div>
			</nav>
		</>
	);
}

export default SlidingNav;
