import React from "react";
import MifflinStJeorEq from "../components/MifflineStJeorEq";
import RecCaloriesEquation from "../components/RecCaloriesEquation";
import ActivityTable from "../components/ActivityTable";
import "./styles/AboutView.css";

const AboutView = () => {
	return (
		<div className="aboutContainer">
			<h1 className="title">About</h1>
			<p className="aboutParagraph">
				Hello, my name is Ryan and I am a software engineer. I have a passion for physical fitness and I have
				experience counting calories to succesfully lose weight. I wanted to make a resource that my friends and
				family could use if they are interested in tracking their calories. This was a personal project so I
				could learn new technologies (Spring boot and React for those interested). I hope that this website can
				also help others learn to count calories and reach their weight goals.
			</p>
			<h2 className="aboutSectionHeading">Equation used for calorie recommendation</h2>
			<p className="aboutParagraph">
				The Mifflin-St Jeor Equation is widely regarded as one of the best/most accurate equations to calculate
				RMR. RMR is your resting metabolic rate, the calories your body burns while at rest. This calculator
				uses the Mifflin-St Jeor Equation and then multiplies the result by an activity coefficient to get your
				total estimated daily calories burned. Based on your input goal, we then add or subtract 500 calories to
				give you a target number of calories you should aim to consume to reach your goal. The relevant
				equations are shown below. Here is a link to the{" "}
				<a className="pubmedLink" href="https://pubmed.ncbi.nlm.nih.gov/2305711/">
					PubMed research paper
				</a>{" "}
				in case you would like to look at the underlying research.
			</p>
			<h2 className="rmrEquationHeader">RMR equation:</h2>
			<MifflinStJeorEq />
			<ActivityTable />
			<RecCaloriesEquation />
			<p className="goodluck">Best of luck reaching your weight goal!</p>
		</div>
	);
};

export default AboutView;
