import React from "react";
import { Link } from "react-router-dom";
import "./styles/BottomToolbar.css";

const BottomToolbar = () => {
	return (
		<>
			<div className="bottomNav">
				<div className="bottomLogo">
					Daily Calorie Calc&nbsp;
					<i className="fa-solid fa-weight-scale"></i>
				</div>
				<div className="linkItemDiv">
					<div className="bottomItem">
						<Link to="/privacy" className="linkText">
							Privacy
						</Link>
					</div>
					<div className="bottomItem">
						<Link to="/contact" className="linkText">
							Contact
						</Link>
					</div>
				</div>
			</div>
		</>
	);
};

export default BottomToolbar;
