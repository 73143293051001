import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SlidingNav from "./components/SlidingNav";
import Home from "./pages/HomeView";
import About from "./pages/AboutView";
import Faq from "./pages/FaqView";
import Results from "./pages/ResultsView";
import Diet from "./pages/DietView";
import CalorieTracking from "./pages/CalorieTrackingView";
import Privacy from "./pages/PrivacyView";
import Contact from "./pages/ContactView";
import BottomToolbar from "./components/BottomToolbar";

function App() {
	return (
		<div className="App">
			<Router>
				<SlidingNav />
				<Routes>
					<Route exact path="/" element={<Home />}></Route>
					<Route exact path="/about" element={<About />}></Route>
					<Route exact path="/faq" element={<Faq />}></Route>
					<Route exact path="/results" element={<Results />}></Route>
					<Route exact path="/resources/diet" element={<Diet />}></Route>
					<Route exact path="/resources/calorie-tracking" element={<CalorieTracking />}></Route>
					<Route exact path="/privacy" element={<Privacy />}></Route>
					<Route exact path="/contact" element={<Contact />}></Route>
					<Route path="*" element={<Navigate to="/" replace={true} />} />
				</Routes>
				<BottomToolbar />
			</Router>
		</div>
	);
}

export default App;
