export default class InputValidator {
	constructor(age, sex, heightCm, weightKg, activityLevel, goal) {
		this.age = Number(age);
		this.sex = sex;
		this.heightCm = Number(heightCm);
		this.weightKg = Number(weightKg);
		this.activityLevel = activityLevel;
		this.goal = goal;
	}

	validate() {
		// console.log("Age = " + this.age + this.validateAge());
		// console.log("Sex = " + this.sex + this.validateSex());
		// console.log("height = " + this.heightCm + this.validateHeight());
		// console.log("weight = " + this.weightKg + this.validateWeight());
		// console.log("activity = " + this.activityLevel + this.validateActivityLevel());
		// console.log("goal = " + this.goal + this.validateGoal());
		return (
			this.validateAge() &&
			this.validateSex() &&
			this.validateHeight() &&
			this.validateWeight() &&
			this.validateActivityLevel() &&
			this.validateGoal()
		);
	}

	validateAge() {
		return typeof this.age === "number" && isFinite(this.age) && Math.round(this.age) === this.age;
	}

	validateSex() {
		return this.sex === "male" || this.sex === "female";
	}

	validateHeight() {
		return typeof this.heightCm === "number" && this.heightCm > 0;
	}

	validateWeight() {
		return typeof this.weightKg === "number" && this.weightKg > 0;
	}

	validateActivityLevel() {
		return (
			this.activityLevel === "sedentary" ||
			this.activityLevel === "light" ||
			this.activityLevel === "moderate" ||
			this.activityLevel === "heavy"
		);
	}

	validateGoal() {
		return this.goal === "gain" || this.goal === "lose" || this.goal === "maintain";
	}
}
