import React from "react";
import UnderConstruction from "../components/UnderConstruction";

const ContactView = () => {
	return (
		<div>
			<h1 className="title">Contact</h1>
			{<UnderConstruction />}
		</div>
	);
};

export default ContactView;
