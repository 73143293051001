import React, { useEffect } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import RecCalories from "../components/RecCalories";
import CalculationEquation from "../components/CalculationEquation";
import RecCaloriesEquation from "../components/RecCaloriesEquation";
import CalorieCalculator from "../formProcessing/CalorieCalculator";
import InputValidator from "../formProcessing/InputValidator";
import ActivityTable from "../components/ActivityTable";
import { heightOptions } from "../data/HeightOptions";
import { activityOptions } from "../data/ActivityOptions";
import "./styles/ResultsView.css";

function ResultsView() {
	const navigate = useNavigate();
	const [queryParameters] = useSearchParams();
	// const [responseData, setResponseData] = useState([]);

	// // URL For AWS ElasticBeanStalk
	// const baseUrl = "https://dailycaloriecalc-env.eba-aenzm4s9.us-west-1.elasticbeanstalk.com";
	// URL For LocalHost
	//const baseUrl = "http://localhost:8080";

	useEffect(() => {
		const sex = queryParameters.get("gender");
		const heightCm = queryParameters.get("heightCm");
		const age = queryParameters.get("age");
		const weightKg = queryParameters.get("weightKg");
		const activityLevel = queryParameters.get("activityLevel");
		const goal = queryParameters.get("goal");
		const validator = new InputValidator(age, sex, heightCm, weightKg, activityLevel, goal);
		try {
			const isValid = validator.validate();
			console.log(isValid);
			if (!isValid) {
				return navigate({ pathname: "/", state: {}, replace: true });
			}
		} catch {
			return navigate({ pathname: "/", state: {}, replace: true });
		}
		window.scrollTo(0, 0);
	}, [navigate, queryParameters]);

	const sex = queryParameters.get("gender");
	const heightCm = queryParameters.get("heightCm");
	const age = queryParameters.get("age");
	const weightKg = queryParameters.get("weightKg");
	const activityLevel = queryParameters.get("activityLevel");
	const goal = queryParameters.get("goal");
	console.log(queryParameters);

	const calculator = new CalorieCalculator(age, sex, heightCm, weightKg, activityLevel, goal);
	let x = calculator.getResult();
	const tdee = x.tdee;
	const recommendedCals = x.dailyCalories;

	function getGoalString() {
		let goalId = queryParameters.get("goal");
		if (goalId === "lose") {
			return "Lose Weight";
		} else if (goalId === "gain") {
			return "Gain Weight";
		} else {
			return "Maintain Weight";
		}
	}
	function getGenderString() {
		let gender = queryParameters.get("gender");
		if (gender === "male") {
			return "Male";
		} else {
			return "Female";
		}
	}

	function getHeightString() {
		let heightString = queryParameters.get("heightCm");
		let units = queryParameters.get("units");
		if (units === "imperial") {
			heightString = heightOptions.find(({ value }) => value === parseFloat(heightString)).label;
		} else {
			heightString += " cm";
		}
		return heightString;
	}

	function getWeightString() {
		let weightString = queryParameters.get("weightKg");
		let units = queryParameters.get("units");
		if (units === "imperial") {
			let weightLbs = (parseFloat(weightString) * 2.205).toFixed();
			weightString = weightLbs + " lbs";
		} else {
			weightString += " kg";
		}
		return weightString;
	}

	function getActivityString() {
		let act = queryParameters.get("activityLevel");
		console.log(act);
		return activityOptions.find(({ value }) => value === act).short;
	}

	return (
		<>
			<h1 className="title">Results</h1>
			<div className="inputParamContainer">
				<h2 className="subtitle">Input Parameters</h2>
				<div className="paramContainer">
					<div className="inputParam">Sex: {getGenderString()}</div>
					<div className="inputParam secondParam">Age: {queryParameters.get("age")}</div>
				</div>
				<div className="paramContainer">
					<div className="inputParam">Height: {getHeightString()}</div>
					<div className="inputParam secondParam">Weight: {getWeightString()}</div>
				</div>
				<div className="paramContainer">
					<div className="inputParam">Activity Level: {getActivityString()}</div>
				</div>
				<div className="paramContainer">
					<div className="inputParam">Goal: {getGoalString()}</div>
				</div>
			</div>
			<div className="recommendedCals testDiv">Recommended Daily Calories: {recommendedCals}</div>
			{RecCalories(queryParameters.get("goal"), tdee)}
			<CalculationEquation />

			<div className="container">
				<p>
					We then multiply the calculated RMR from the Mifflin-St Jeor Equation by the selected activity
					coefficient to calculate your TDEE. TDEE is your total daily energy expenditure, also known as your
					maintenance calories.
				</p>
			</div>

			<ActivityTable />
			<RecCaloriesEquation />

			{/* Ad here */}

			<h1 className="subtitle">What now?</h1>
			<div className="container">
				<p>
					Now that we know the estimated recommend calories, we can start tracking our calories and try to
					stay within our daily limit. It is also important to remember that this is an estimated nbumber of
					daily calories, so it is best to stay diligent while tracking to see if any further changes will be
					needed. If you track your calories diligently for a week or two and no changes are being seen, then
					adjust your calories by 100 for about a week and try again.
				</p>
				<p>
					After your weight has changed (or if your activity level changes) you can calculate your recommend
					calories again to see if you should update your current calorie budget.
				</p>
			</div>

			<div className="simpleFaq">
				<h1 className="subtitle">How should I track calories?</h1>
				<div className="container">
					<p>
						Counting calories is essentially keeping track of what you have eaten and the amount of calories
						you have consumed. The best way to keep track of your caloires is by using a food tracking app
						like MyFitnessPal or LoseIt!. These apps let you scan barcodes of different food items to record
						the corresponding nutrition information. In conjunction with these apps, you will need to
						measure your food to get the most accurate totals for the amount of food you eat. I recommend
						using a food scale to weight out your food to be as precise as possible. These food tracking
						apps also have community input/created food recipies, so you can find food from your favorite
						restaraunts so that you can still keep track of your calories/diet while eating out.
					</p>
					<p className="centerPara">
						For more information checkout the{" "}
						<Link className="linkTextBlue" to={"/resources/calorie-tracking"}>
							Calorie Tracking resources page
						</Link>
						.
					</p>
				</div>
				<h1 className="subtitle">What foods should I try to eat (and what should I avoid)?</h1>
				<div className="container">
					<p>
						Foods that are high in protein are a staple. Protein rich foods have high satiety, keeping you
						full longer which helps avoid overeating and snacking. Vegetables and frutis are also
						beneficial, since for the most part they are lower in calories and help improve your overall
						health. Anohter benefit of eating a high protein diet is that it helps you better maintain your
						current muscle mass while you lose weight and helps you build more muscle when gaining weight.
					</p>
					<p className="centerPara">
						For more information checkout the{" "}
						<Link className="linkTextBlue" to={"/resources/diet"}>
							Diet resources page
						</Link>
						.
					</p>
				</div>
				<h1 className="subtitle">Should I exercise more?</h1>
				<div className="container">
					<p>
						Exercise is great, and it is recommened to do a combination of weight tranining and cardio to
						improve your overall fitness. However, one caveat would be that if you are new to counting
						calories (and aim to lose weight), if you drastically increase your exercise right off the bat
						you would be in a greater risk to fall off the diet since you are adjusting to many different
						lifestyle changes at once and it can become mentally draining. I would recommend to slowly
						increase your excercise/activty level while you get accustomed to counting calories, and become
						used to the overall less amount of food you are consuming compared to your the calories you
						consumed pre-diet.
					</p>
					<p>
						For people looking to gain weight it is highly suggested to lift weights and eat a high protein
						diet. By doing both of these things you can better add muscle to your frame while you are
						bulking up. If you are eating at a caloric surplus, but are not eating a good amount of protein
						and not lifting weights, you will primarily be adding fat to your body and no one wants that.
					</p>
				</div>
				<h1 className="centeredText">
					For more information and tips check out the different Resources pages and the{" "}
					<Link className="linkTextBlue" to={"/faq"}>
						FAQ
					</Link>
					!
				</h1>
				{/* Ad here */}
			</div>
		</>
	);
}
export default ResultsView;
