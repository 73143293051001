import React from "react";
import UnderConstruction from "../components/UnderConstruction";

const CalorieTrackingView = () => {
	return (
		<div>
			<h1 className="title">Calorie Tracking</h1>
			{<UnderConstruction />}
		</div>
	);
};

export default CalorieTrackingView;
