import React, { useState, useRef } from "react";
import Select from "react-select";
import { useNavigate, createSearchParams } from "react-router-dom";
import { useForm, useController } from "react-hook-form";
import { string, number, z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { genderOptions } from "../data/GenderOptions";
import { heightOptions } from "../data/HeightOptions";
import { activityOptions } from "../data/ActivityOptions";
import "./styles/InputForm.css";

const schema = z.object({
	weight: number({ message: "Please select your weight" }).positive({ message: "Please select a valid weight" }),
	height: number({ message: "Please select your height" }).positive({ message: "Please select a valid weight" }),
	gender: string({ message: "Please select gender" }),
	age: number({ message: "Please select a valid age" })
		.int({ message: "Please select a valid age" })
		.min(1, { message: "Please select a valid age" })
		.max(100, { message: "Please select a valid age" }),
	activity: string({ message: "Please select your activity level" }),
});

const InputForm = ({ user = {} }) => {
	const { register, resetField, control, handleSubmit, formState } = useForm({
		defaultValues: user,
		resolver: zodResolver(schema),
	});

	const { errors } = formState;
	const navigate = useNavigate();

	const { field } = useController({ name: "gender", control });
	const handleSelectChange = (option) => {
		field.onChange(option.value);
	};

	const { field: actF } = useController({ name: "activity", control });
	const handleActivitySelectChange = (option) => {
		actF.onChange(option.value);
	};
	const { field: heightF } = useController({ name: "height", control });
	const handleHeightSelectChange = (option) => {
		if (option !== null) {
			heightF.onChange(option.value);
		}
	};
	const [clickedId, setClickedId] = useState("imperial");
	const [clickedGoalId, setClickedGoalId] = useState("lose");
	const selectHeightRef = useRef();

	function navigateToResults(formValues) {
		let weight = formValues.weight;
		if (clickedId === "imperial") {
			weight = Math.round((formValues.weight / 2.205) * 100) / 100;
		}

		let params = {
			gender: formValues.gender,
			age: formValues.age,
			heightCm: formValues.height,
			weightKg: weight,
			activityLevel: formValues.activity,
			goal: clickedGoalId,
			units: clickedId,
		};
		navigate({ pathname: "/results", search: `?${createSearchParams(params)}` });
	}

	const handleFormatClick = (name) => {
		if (name !== clickedId) {
			resetField("height");
			resetField("weight");
			if (selectHeightRef.current !== null) {
				selectHeightRef.current.clearValue();
			}

			setClickedId(name);
		}
	};

	const sendRequestToServer = (formValues) => {
		navigateToResults(formValues);
	};

	// const selectStyles = {
	// 	valueContainer: () => ({
	// 		padding: 10,
	// 		display: "flex",
	// 		// alignItems: "center",
	// 	}),
	// 	menu: () => ({
	// 		zIndex: 50,
	// 		// position: "fixed",
	// 		// position: "relative",
	// 		backgroundColor: "#fff",
	// 	}),
	// };

	return (
		<>
			{/* Regular Form */}
			<div className="form-container hideOnMobile">
				<form className="formPadding" onSubmit={handleSubmit(sendRequestToServer)}>
					<div className="widthScreen">
						<h1 className="inputFormHeader">Input Form</h1>
						<div className="top-form-section">
							<div>
								<h3>Age</h3>
								<input
									className="age-input"
									{...register("age", {
										setValueAs: (value) => Number(value),
									})}
								/>
								<div style={{ color: "red" }}>{errors.age?.message}</div>
							</div>

							<div className="gender-selection-div">
								<h3>Sex</h3>
								<Select
									value={genderOptions.find(({ value }) => value === field.value)}
									onChange={handleSelectChange}
									placeholder={<div>Select sex</div>}
									isSearchable={false}
									options={genderOptions}></Select>
								<div style={{ color: "red" }}>{errors.gender?.message}</div>
							</div>
						</div>

						<div className="btn-metric-section">
							<button
								key={"imperial"}
								type="button"
								onClick={() => handleFormatClick("imperial")}
								className={"imperial" === clickedId ? "customButton active" : "customButton"}>
								Imperial
							</button>
							<button
								key={"metric"}
								type="button"
								onClick={() => handleFormatClick("metric")}
								className={clickedId === "metric" ? "customButton active" : "customButton"}>
								Metric
							</button>
						</div>

						<div className="weight-section">
							<h3>Weight</h3>
							<input
								className="weight-input"
								{...register("weight", {
									setValueAs: (value) => Number(value),
								})}
								placeholder={"imperial" === clickedId ? "lbs" : "kg"}
							/>
							<div style={{ color: "red" }}>{errors.weight?.message}</div>
						</div>

						<div className="height-section">
							<h3>Height</h3>
							{(() => {
								if (clickedId === "imperial") {
									return (
										<div className="selectorWidth">
											<Select
												ref={selectHeightRef}
												value={heightOptions.find(({ value }) => value === heightF.value)}
												onChange={handleHeightSelectChange}
												placeholder={<div>Select height</div>}
												isSearchable={false}
												options={heightOptions}
												menuShouldScrollIntoView={false}></Select>
										</div>
									);
								} else {
									return (
										<div>
											<input
												className="height-input"
												{...register("height", {
													setValueAs: (value) => Number(value),
												})}
												placeholder="cm"
											/>
										</div>
									);
								}
							})()}
							<div style={{ color: "red" }}>{errors.height?.message}</div>
						</div>

						<div className="activity-section selectorWidth">
							<h3>Activity Level</h3>
							<Select
								value={activityOptions.find(({ value }) => value === actF.value)}
								onChange={handleActivitySelectChange}
								placeholder={<div>Select activity level</div>}
								isSearchable={false}
								options={activityOptions}></Select>
							<div style={{ color: "red" }}>{errors.activity?.message}</div>
						</div>

						<h3>Weight Goal</h3>
						<div className="btn-metric-section">
							<button
								key={"lose"}
								type="button"
								onClick={() => setClickedGoalId("lose")}
								className={clickedGoalId === "lose" ? "customButton active" : "customButton"}>
								Lose
							</button>
							<button
								key={"gain"}
								type="button"
								onClick={() => setClickedGoalId("gain")}
								className={"gain" === clickedGoalId ? "customButton active" : "customButton"}>
								Gain
							</button>
							<button
								key={"maintain"}
								type="button"
								onClick={() => setClickedGoalId("maintain")}
								className={"maintain" === clickedGoalId ? "customButton active" : "customButton"}>
								Maintain
							</button>
						</div>

						<div className="submit-container">
							<button type="submit" className="submit-btn">
								Submit
							</button>
						</div>
					</div>
				</form>
			</div>

			{/* Mobile Form */}
		</>
	);
};

export default InputForm;
